<template>
  <section>
    <section class="search-container">
      <a-form
        :form="fileForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="设备名称">
          <a-input
            autocomplete="off"
            v-decorator="['deviceName']"
            placeholder="请输入"
          />
        </a-form-item>
        <!-- <a-form-item label="任务状态">
          <a-select placeholder="请选择"></a-select>
        </a-form-item> -->
        <a-form-item label="发起时间">
          <a-range-picker v-model="sponsorTime">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <a-form-item label="归档时间">
          <a-range-picker v-model="finishTime">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            >查询</a-button
          >
          <a-button @click="reset">重置</a-button>
        </a-form-item>
      </a-form>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{ showQuickJumper: false, total: list.total }"
      >
        <template slot="operation" slot-scope="list, text">
          <a style="color: #529aff" @click="checkFile(text)">查看</a>
        </template>
      </a-table>
    </section>

    <a-drawer
      class="drawer_module"
      title="维保详情"
      placement="right"
      :visible="deviceVisible"
      :width="350"
      @close="onClose"
      :maskClosable="true"
    >
      <!-- 进度状态 -->
      <section>
        <section class="deviceData">
          <a-row class="m-b-10" type="flex" justify="end">
            <a-col :span="6" style="color: #4b80cb">
              <span style="cursor: pointer">进度详情</span>
              <!-- <a-badge :dot="show">
                <span style="cursor:pointer" 
                  >进度详情</span
                >
              </a-badge> -->
            </a-col>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 设备名称 </a-col>
            <span>{{ checkFileData.deviceName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 发起人 </a-col>
            <span>{{ checkFileData.customerName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ checkFileData.customerPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 维保负责人 </a-col>
            <span>{{ checkFileData.maintainerName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ checkFileData.maintainerPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 验收负责人 </a-col>
            <span>{{ checkFileData.acceptorName }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 联系方式 </a-col>
            <span>{{ checkFileData.acceptorPhone }}</span>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 照片问题 </a-col>
            <a-col :span="16">
              <a-row type="flex" justify="start">
                <img
                  v-for="(item, index) in faultPhotoUrlList"
                  @click="previewImg(item)"
                  :key="index"
                  :src="item"
                  style="width: 20%; height: 40px; margin-right: 4px"
                  alt=""
                />
                <!-- <img
                  src="../../../assets/image/header/pic.png"
                  style="width:20%;height:40px"
                  alt=""
                />
                <img
                  src="../../../assets/image/header/pic.png"
                  style="width:20%;height:40px"
                  alt=""
                />
                <img
                  src="../../../assets/image/header/pic.png"
                  style="width:20%;height:40px"
                  alt=""
                />
                <img
                  src="../../../assets/image/header/pic.png"
                  style="width:20%;height:40px"
                  alt=""
                /> -->
              </a-row>
            </a-col>
          </a-row>
          <a-row class="m-b-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 问题说明 </a-col>
            <span>{{ checkFileData.faultDescription }}</span>
          </a-row>
        </section>

        <section class="explain">
          <a-row
            class="m-b-10 m-t-10"
            type="flex"
            justify="start"
            v-for="(item, index) in maintenanceAttachList"
            :key="index"
          >
            <a-col :span="8" style="color: #4b80cb">
              {{ index == "0" ? "维保附件" : "" }}
            </a-col>
            <a-col :span="14">
              <!-- <span>{{ item }}</span> -->
              <span>
                <img
                  :src="item"
                  @click="previewImg(item)"
                  style="width: 20%; height: 40px; margin-right: 4px"
                  alt=""
                />
              </span>
              <a @click="downLoadPng(item)" style="float: right">下载</a>
            </a-col>
          </a-row>
          <a-row class="m-t-18" type="flex" justify="start">
            <a-col :span="8" style="color: #4b80cb"> 维保备注 </a-col>
            <a-col>
              <span>{{ checkFileData.maintenanceRemarks }}</span>
            </a-col>
          </a-row>

          <a-row class="form-btn-group">
            <a-button class="close_button" @click="deviceVisible = false"
              >关闭</a-button
            >
          </a-row>
        </section>
      </section>

      <!-- <div
        :style="{
          position: 'absolute',
          right: '10px',
          bottom: '20px',
          width: '100%',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1
        }"
      >
        <a-button
          :style="{
            marginRight: '8px',
            backgroundColor: '#1c3d6c',
            border: 'none'
          }"
          type="primary"
          @click="save"
        >
          提交
        </a-button>
        <a-button @click="close">
          关闭
        </a-button>
      </div> -->
    </a-drawer>

    <!-- 图片预览 -->
    <a-modal
      centered
      width="800px"
      class="modal-container"
      title="图片预览"
      v-model="previewImageModal.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row class="text-center" type="flex" justify="center">
        <img
          style="background: #fff; width: 80%"
          :src="previewImageModal.url"
          alt=""
        />
      </a-row>
    </a-modal>
  </section>
</template>
<script>
import baseURL from "@/api/env";
export default {
  data() {
    return {
      previewImageModal: {
        visible: false,
        url: "",
      },
      list: {
        columns: [
          { title: "序号", dataIndex: "key", align: "center" },
          { title: "设备名称", dataIndex: "deviceName", align: "center" },
          { title: "发起人", dataIndex: "customerName", align: "center" },
          { title: "处理人", dataIndex: "maintainerName", align: "center" },
          { title: "验收人", dataIndex: "acceptorName", align: "center" },
          { title: "发起时间", dataIndex: "createTime", align: "center" },
          { title: "归档时间", dataIndex: "updateTime", align: "center" },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
        total: 0,
        pageNum: 1,
      },
      deviceVisible: false,
      startTime: "",
      endTime: "",
      finishTime: ["", ""], //归档时间
      sponsorTime: ["", ""], //发起时间
      finishStartTime: "",
      finishEndTime: "",
      deviceName: "",
      checkFileData: {},
      faultPhotoUrlList: [],
      maintenanceAttachList: [],
      fileForm: this.$form.createForm(this),
    };
  },
  created() {
    this.deviceMaintenanceList();
  },
  methods: {
    // 下载,
    downLoadPng(url) {
      window.open(
        baseURL +
          "/personnel/upload/download.do?url=" +
          url +
          "&&exprotFile=维保附件",
        "_blank"
      );
    },
    // 图片预览
    previewImg(url) {
      this.previewImageModal.visible = true;
      this.previewImageModal.url = url;
    },
    // 档案列表
    deviceMaintenanceList() {
      let params = {
        startTime: this.startTime,
        endTime: this.endTime,
        deviceName: this.deviceName,
        current: this.list.pageNum,
        finishStartTime: this.finishStartTime,
        finishEndTime: this.finishEndTime,
      };
      this.$api.deviceMaintenanceList(params).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.list.total = res.data.total ? res.data.total : 0;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
            });
          }
          this.list.data = res.data.records;
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.fileForm.getFieldsValue();
      console.log(formVal);

      this.startTime = this.sponsorTime[0]
        ? this.sponsorTime[0].format("YYYY-MM-DD HH:MM:ss")
        : "";
      this.endTime = this.sponsorTime[1]
        ? this.sponsorTime[1].format("YYYY-MM-DD HH:MM:ss")
        : "";
      this.finishStartTime = this.finishTime[0]
        ? this.finishTime[0].format("YYYY-MM-DD HH:MM:ss")
        : "";
      this.finishEndTime = this.finishTime[1]
        ? this.finishTime[1].format("YYYY-MM-DD HH:MM:ss")
        : "";

      // this.startTime = formVal.time
      //   ? formVal.time[0].format("YYYY-MM-DD hh:mm:ss")
      //   : "";
      // this.endTime = formVal.time
      //   ? formVal.time[1].format("YYYY-MM-DD hh:mm:ss")
      //   : "";
      // this.finishStartTime =
      //   formVal.finishTime || formVal.finishTime.length > 0
      //     ? formVal.finishTime[0].format("YYYY-MM-DD hh:mm:ss")
      //     : "";
      // this.finishEndTime =
      //   formVal.finishTime || formVal.finishTime.length > 0
      //     ? formVal.finishTime[1].format("YYYY-MM-DD hh:mm:ss")
      //     : "";
      this.deviceName = formVal.deviceName;
      this.list.pageNum = 1;
      this.deviceMaintenanceList();
    },
    // 重置
    reset() {
      this.startTime = "";
      this.endTime = "";
      this.deviceName = "";
      this.sponsorTime = ["", ""];
      this.finishTime = ["", ""];
      this.list.pageNum = 1;
      this.finishStartTime = "";
      this.finishEndTime = "";
      this.fileForm.resetFields();
      this.deviceMaintenanceList();
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.deviceMaintenanceList();
    },

    checkFile(item) {
      this.$api.deviceMaintenance({ id: item.id }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.checkFileData = res.data;
          this.faultPhotoUrlList = this.checkFileData.faultPhotoUrl
            ? this.checkFileData.faultPhotoUrl.split(",")
            : [];
          this.maintenanceAttachList = this.checkFileData.maintenanceAttach
            ? this.checkFileData.maintenanceAttach.split(",")
            : [];
          // console.log(item)
          this.deviceVisible = true;
        }
      });
    },
    // save() {},
    // close() {
    //    this.deviceVisible = false;
    // },
    onClose() {
      this.deviceVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}

/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
.explain {
  margin-top: 30px;
  border-top: 1px dashed;
}
</style>