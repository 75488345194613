/**
 * des: env 环境地址
 */

const CONFIG = {
	// dev: 'http://120.78.5.15:9999',
	dev: 'http://192.168.1.192:9999',

	//pro: location.origin + '/api'
	pro: 'http://192.168.1.192:9999'
	// pro: 'http://120.78.5.15:9999'
}

export default process.env.NODE_ENV === 'production' ? CONFIG.pro : CONFIG.dev
